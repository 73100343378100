// AREAS MANAGER
const areasManager = [
  {
    id: 1,
    name: "Seguridad",
    clients: [
      { id: 1, name: "Carlos", last_name:"Gonzales", area_id: 1 },
      { id: 2, name: "Raul", last_name:"Perez", area_id: 1 }
    ]
  },
  {
    id: 2,
    name: "Administración",
    clients: [
      { id: 3, name: "Manuel", last_name:"Rivera", area_id: 2 },
      { id: 4, name: "Liliana", last_name:"Misajel", area_id: 2 }
    ]
  }
];

export { areasManager };