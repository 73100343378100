export default {
	name: 'FormBody',
	props: ['value','group','disabled'],
	computed: {
		inputs: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit("input", value);
			},
		},
	},
};
