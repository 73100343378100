import { mapActions } from 'vuex';
import { $EventBus } from '@/main';
export default {
	name: 'ToolDialog',
	props: ['value', 'toolsSelected'],
	data: () => ({
		toolsTable: [],
		toolSelected: null,
		newToolName: '',
		isLoadingSaveTool: false,
	}),
	computed: {
		tools() {
			return this.$store.state.tool.tools;
		},
		toolDialog: {
			get() {
				return this.value || false;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
	watch: {
		value(newVal) {
			if (newVal) {
				this.toolsTable = [];
				this.toolSelected = null;
			}
		},
	},
	methods: {
		...mapActions('tool', ['saveTool']),
		addToolTable(toolSelected) {
			const isRepeat = this.toolsTable.some((tool) => tool.id === toolSelected.id);
			if (!isRepeat) {
				this.toolsTable.push({ ...toolSelected });
				this.toolSelected = null;
			}
		},
		removeToolTable(id) {
			const index = this.toolsTable.findIndex((tool) => tool.id === id);
			if (index >= 0) this.toolsTable.splice(index, 1);
		},
		addToolsInpections() {
			this.$emit('addToolsInspections', [...this.toolsTable]);
			this.toolDialog = false;
		},
		async saveNewTool() {
			try {
				this.isLoadingSaveTool = true;
				const response = await this.saveTool({ name: this.newToolName });
				this.addToolTable(response);
				this.newToolName = '';
				$EventBus.$emit('showSnack', 'success', 'Herramienta añadida');
			} catch (error) {
				const msmError = error?.response?.data?.message || 'Algo salio mal, no se guardo la información';
				$EventBus.$emit(
					'showSnack',
					'warning',
					msmError
				);
			} finally {
				this.isLoadingSaveTool = false;
			}
		},
	},
};
